import React from "react";
import { ITable } from "./types";

const isCheck = (str?: string | null) => {
  if (!str) return "";
  if (str === "check") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5 13l4 4L19 7"
        />
      </svg>
    );
  }
  return str;
};

const PricingTable = ({ title, features, header }: ITable) => {
  console.log({ title, features, header });
  const isOdd = (id) => {
    return id % 2 !== 0;
  };
  return (
    <>
      <h2 className="dx-h3 text-center">各プランの詳細</h2>
      <h3 className="my-4 font-bold text-dsc-theme-primary">{title}</h3>
      <div className="flex flex-col w-full overflow-x-auto">
        <table className="w-full table-fixed lg:table-auto ">
          {header && (
            <thead className="w-full border-b">
              <tr className="bg-dsc-theme-card-bg">
                <th className="p-4 w-[200px] md:w-[400px] text-left">
                  {header.title}
                </th>
                <th className="p-4 w-[200px]">{header.basic}</th>
                <th className="p-4 w-[200px]">{header.plus}</th>
                <th className="p-4 w-[200px]">{header.business}</th>
                <th className="p-4 w-[200px]">{header.enterprise}</th>
              </tr>
            </thead>
          )}

          <tbody className="">
            {features?.map((feature, index) => (
              <tr
                key={feature.title}
                className={` border-b hover:bg-dsc-theme-card-bg  ${
                  isOdd(index) && "bg-dsc-theme-card-bg"
                }`}
              >
                <td className="p-4 border-b ">{feature.title}</td>
                <td className="p-4 text-center [&>svg]:mx-auto">
                  {isCheck(feature.basic)}
                </td>
                <td className="p-4 text-center [&>svg]:mx-auto">
                  {isCheck(feature.plus)}
                </td>
                <td className="p-4 text-center [&>svg]:mx-auto">
                  {isCheck(feature.business)}
                </td>
                <td className="p-4 text-center [&>svg]:mx-auto">
                  {isCheck(feature.enterprise)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default PricingTable;
