import * as React from "react";
import Layout from "../components/common/layout";
import PricingSection from "../components/pricing/pricing-section";
import Divider from "../components/common/divider";
import PricingTable from "../components/pricing/table";

import { graphql } from "gatsby";
import Meta from "../components/common/meta/SEO";
const PricingPage = ({ data }) => {
  console.log({ data });
  const detailPricing = data?.strapiTable;
  return (
    <Layout>
      <div className="px-4 md:px-8 mt-20 text-dsc-theme-muted-500">
        <h1 className="dx-h1 text-center mb-8">価格</h1>
        <h2 className="dx-h3 text-center mb-20">
          御社のビジネスに最適なプランをお選びください
        </h2>
      </div>
      <div className="px-4 md:px-8 lg:mx-0 text-dsc-theme-muted-500">
        <PricingSection />
        <Divider />
        {detailPricing && <PricingTable {...detailPricing} />}
      </div>
      <Divider />
    </Layout>
  );
};

export const query = graphql`
  {
    strapiTable {
      title
      header {
        title
        basic
        plus
        business
        enterprise
      }
      features {
        title
        basic
        plus
        business
        enterprise
      }
    }
  }
`;

export default PricingPage;

export const Head = ({ location }) => {
  const meta = {
    title: "価格｜Secure Privacy正規販売代理店 DXable",
    description:
      "Secure Privacyの最も高度な機能のロックを解除し、クッキー同意管理を大規模に展開することができます。お客様のビジネスに最適なプランをお選びください。",
    pathname: location.pathname,
  };
  return <Meta meta={meta} />;
};
