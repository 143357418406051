import React from 'react';

const Divider = () => {
  return (
      <div className="mb-[120px]">
          
      </div>
  );
};

export default Divider;
