import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../../common/button";

type PricingCardProps = {
  title: string;
  pricing?: number;
  summary: string;
};

function PricingCard({ title, pricing, summary }: PricingCardProps) {
  return (
    <div className="border border-dsc-theme-divider shadow-sm py-4 rounded-2xl text-center h-full min-w-[200px] max-w-[200px]">
      <div className="px-5">
        <p className="font-bold mb-4 text-lg ">{title}</p>
        {pricing ? (
          <p className="leading-relaxed ">
            <span className="text-dsc-theme-primary text-[26px] font-medium">${pricing}</span>
            <br />
            <span>1ウェブサイト・年額</span>
          </p>
        ) : (
          <p className="px-5 min-h-[68px]">お問い合わせください</p>
        )}
      </div>
      <div className="mt-4 mb-10 border-t border-dsc-theme-divider mx-3"></div>
      <div className="px-4">
        <p className="min-h-[96px]">{summary}</p>
        <div className="my-6">
          <Button to={"/contact-us/"} >お問い合わせ</Button>
        </div>
      </div>
    </div>
  );
}
export default PricingCard;
